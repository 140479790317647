import { useState } from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './components/ScrollToTop';
import { 
  AboutUsPage, 
  ContactPage, 
  ExactControlsPage, 
  ExactServicesPage, 
  ExactSystemsPage, 
  HomePage, 
  NavigationBar, 
  NavigationMenu, 
  ServicesPage 
} from './components';

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <NavigationBar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
      />
      {isMenuOpen && <NavigationMenu onClose={() => setIsMenuOpen(false)} />}
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutUsPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/exact-controls" element={<ExactControlsPage/>}/>
        <Route path="/exact-systems" element={<ExactSystemsPage/>}/>
        <Route path="/exact-services" element={<ExactServicesPage/>}/>
      </Routes>
    </>
  );
}
